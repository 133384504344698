import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache,ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from "../environments/environment";


const uri = environment.apiAdmintHost; // <-- add the URL of the GraphQL server here
// const uri = 'http://localhost:3000/graphql/adminpanel';
export function createApollo(httpLink: HttpLink) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  }


  const auth = setContext((operation, context) => {
    const tokenData = localStorage.getItem('auth_app_token');
    //console.log('TOKEN', JSON.parse(token).value);
    const token = JSON.parse(tokenData).value;
    console.log('TOKEN', token);
    if (token === null) {
      console.log('Token', token);
      return {};
    } else {
      return {
        headers: {
          Authorization: `${token}`
        }
      };
    }
  });

  const link = ApolloLink.from([basic, auth, httpLink.create({ uri })]);
  const cache = new InMemoryCache({
    addTypename: false,
  });
  //console.log('Token', link);
  return {
    link,
    cache,
    defaultOptions: defaultOptions,
    // acceptImmutableResults: false
  }
}

@NgModule({
  exports: [
    HttpClientModule,
  ],
  providers: [{
    provide: APOLLO_OPTIONS,
    useFactory: createApollo,
    deps: [HttpLink]
  }]
})
export class GraphQLModule {}

