import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  private user: any;
  private userId: any;

  constructor(private authService: NbAuthService, private router: Router) {
  }

  canActivate() {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          console.log('USER', token);
          this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
          console.log('payload', this.user);
          if(!this.user || !this.user.userId && this.user.userId !== '60cce08d2255a60011b41d57' && this.user.userId !== '60cce08d2255a60011b41d58') {
            this.router.navigate(['auth/login']);
          }
        }

      });
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          }
        }),
      );
  }
}
